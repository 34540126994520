import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import Image from './image'

const StyledDiv = styled.div`
  background-color: white;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  img {
    margin-bottom: 0;
  }
`

const Organizer = () => {

  const data = useStaticQuery(graphql`
    query {
      contentJson {
        conferenceInfo {
          sponsors {
            name
            logo
          }
          organizer {
            name
            logo
          }
        }
      }
    }
  `)

  return (
    <section className="organizer">
      <div className="container content">
        <div className="columns has-text-centered">
          <div className="column">
            <h3>Organizer</h3>
            <StyledDiv>
              <Image
                imgName={data.contentJson.conferenceInfo.organizer.logo}
                alt={data.contentJson.conferenceInfo.organizer.name + " logo"}
              />
            </StyledDiv>
          </div>
        </div>
        <div className="columns has-text-centered">
          <div className="column">
            <h3>Sponsors</h3>
            {data.contentJson.conferenceInfo.sponsors.map(s => {
              return (
                <StyledDiv>
                  <Image
                    imgName={s.logo}
                    alt={s.name + " logo"}
                  />
                </StyledDiv>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Organizer
