import React from "react"

const Footer = ({ previousYears, contactInfo }) => {
  return (
    <footer className="footer is-size-6 is-family-primary">
    <div className="container">
      <div className="columns has-text-centered">
        <div className="column">
          <a href={contactInfo.email.link}>{contactInfo.email.label}</a>
        </div>
      </div>
      <div className="columns has-text-centered">
        <div className="column">
          <a href={contactInfo.twitterHandle.link}>{contactInfo.twitterHandle.label}</a>
        </div>
      </div>
      <div className="columns has-text-centered">
        <div className="column">
          <h3 className="is-size-5 has-text-weight-semibold">Previous years</h3>
          <div className="column">
            <div className="columns is-gapless is-mobile is-multiline has-text-center">
            {previousYears.map(previous => {
              return (
                <a className="column" href={previous.link} key={previous.year}>
                  <div className="column">{previous.year}</div>
                </a>
              )
            })}
            </div>
          </div>
        </div>
      </div>
      </div>
    </footer>
  )
}

export default Footer
