import React from "react"
import { slide as Menu } from 'react-burger-menu'
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const Nav = () => {

  const showSettings = (event) => {
    event.preventDefault();
  }

  const data = {
    allMdx: {
      nodes: []
    }
  }

  return (
    <div>
      <div className="is-hidden-desktop">
        <Menu width="100%" right noOverlay>
          <Link to="/">Home</Link>
          {data.allMdx.nodes.map(page => {
          return (
            <Link to={page.fields.slug} key={page.fields.slug}>{page.frontmatter.title}</Link>
          )
        })}
        </Menu>
      </div>
      <div className="is-hidden-touch container desktopMenu">
        <div className="columns has-text-centered">
          <Link className="column" to="/">Home</Link>
          {data.allMdx.nodes.map(page => {
            return (
              <Link className="column" to={page.fields.slug} key={page.fields.slug}>{page.frontmatter.title}</Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Nav
