import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
        query {
            allImageSharp {
                edges {
                  node {
                    fixed(width: 120) {
                      ...GatsbyImageSharpFixed
                      originalName
                    }
                  }
                }
              }
        }
        */

const Image = ({ imgName, alt }) => (
    <StaticQuery
      query={graphql`
        query {
            allImageSharp {
                edges {
                  node {
                    fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                      originalName
                    }
                  }
                }
              }
        }
        `}
        render={data => {
            const image = data.allImageSharp.edges.find(
              edge => edge.node.fixed.originalName === imgName
            )
            if (!image) {
              return null
            }
            return <Img fixed={image.node.fixed} alt={alt} />
          }}
    />
  )
  export default Image
